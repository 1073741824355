import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Routes } from '../routes/routes';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ){ }
  
  /**
   * 
   * Order
   * 
   */
  orderLink(){
    return{
      order: ():any => {
        return {
          show: (id:any):any => {

            let url = Routes.orderLink.order.url+"/show/"+id;
        
            return this.http.get(url);

          },
          payment: (dataItem):any => {  

            let url = Routes.orderLink.order.url+"/payment";
    
            return this.http.post(url,dataItem);  
    
          },
          paymentConsult: (dataItem:any):any => {

            let url = Routes.orderLink.order.url+"/payment/consult";

            let params = dataItem;
  
            return this.http.post(url,params);  

          },
          voucher: ():any => {
            return {
              download: (id):any => {

                let url      = Routes.orderLink.order.url+"/voucher/download";
                let dataItem = {
                  id: id
                }

                return this.http.post(url,dataItem);  

              },
              email: (dataItem):any => {

                let url = Routes.orderLink.order.url+"/voucher/email";

                return this.http.post(url,dataItem);  

              }
            }
          }
        }
      }
    }
  }
  /**
   * 
   * Payment link
   * 
   */
  paymentLink(){
    return {
      order: ():any => {
        return {
          show: (id):any => {

            let url = Routes.paymentLink.order.url+"/show/"+id;
    
            return this.http.get(url);
    
          },
          payment: (dataItem):any => {

            let url = Routes.paymentLink.order.url+"/payment";
    
            return this.http.post(url,dataItem);  
    
          },
          voucher: ():any => {
            return {
              download: (id):any => {

                let url      = Routes.paymentLink.order.url+"/voucher/download";
                let dataItem = {
                  id: id
                }

                return this.http.post(url,dataItem);  

              },
              email: (dataItem):any => {

                let url      = Routes.paymentLink.order.url+"/voucher/email";

                return this.http.post(url,dataItem);  

              }
            }
          }
        }
      }
    }
  }
  /**
   * 
   * Notifications
   * 
   * @returns 
   * 
   * 
   */
  notification(){
    return {
      partner: ():any => {

        let url = Routes.notification.partner;

        return this.http.get(url);

      }
    }
  }
  /**
   * 
   * User
   * 
   * @returns 
   * 
   * 
   */
  user(){
    return{
      show: ():any => {

        let url = Routes.register.user.show;

        return this.http.get(url);

      },
      save: (dataItem):any => {

        let url = Routes.register.user.profile;

        return this.http.post(url,dataItem);

      }
    }
  }
  /**
   * 
   * Report
   * 
   */
  report(){
    return{
      dashboard: ():any => {
        return {
          home: ():any => {

            let url = Routes.report.dashboard.home.url;
            return this.http.get(url);

          },
          saleMonthYear: (year=null):any => {

            let url = Routes.report.dashboard.sale_month_year.url;

            let params = {
              year: year
            }

            return this.http.get(url,{params});  

          },
          saleListUsers: (year=null,month=null):any => {

            let url = Routes.report.dashboard.sale_list_users.url;  

            let params = {
              year: year,
              month: month
            }

            return this.http.get(url,{params});  

          },
          saleServices: (year=null):any => {

            let url = Routes.report.dashboard.sale_services.url;

            return this.http.get(url);  

          },
          saleCategories: (year=null):any => {

            let url = Routes.report.dashboard.sale_categories.url;

            return this.http.get(url);  

          }
        }
      }
    }
  }
  /**
   * 
   * Flight
   * 
   * @returns
   * 
   */
  flight(){
    return{
      airport: ():any => {
        return {
          combo: ():any => {

            let url = Routes.flight.airport.combo;
            return this.http.get(url);

          }
        }
      },
      airline: ():any => {
        return {
          combo: ():any => {

            let url = Routes.flight.airline.combo;
            return this.http.get(url);

          }
        }
      }
    }
  }
  /**
   * 
   * Cart
   * 
   * @returns 
   * 
   */
  sale(){
    return {
      cart: ():any => {
        return {
          delete: (id):any => {

            let url =  Routes.sale.cart.url+"/"+id;   
            return this.http.delete(url);

          },
          store: (params):any => {
            let url =  Routes.sale.cart.url;   
            return this.http.post(url,params);
          },
          new: ():any => {
            let url =  Routes.sale.cart.new;   
            return this.http.post(url,{});
          },
          get: (id=null,methods=null):any => {
            
            let url        =  Routes.sale.cart.url;  
            let params:any = {
              id: id
            }
            if(methods != null){
              params.methods = methods;
            }
            return this.http.get(url,{params});  

          },
          download: (id):any => {
            
            let url    =  Routes.sale.cart.download;  
            let params = {
             id: id
            }
            return this.http.post(url,params);  
          
          },
          shareEmail: (params):any => {
            
            let url    =  Routes.sale.cart.share_email;  
 
            return this.http.post(url,params);  

          }
        }
      },
      order: ():any => {
        return {
          faturar: (dataItem):any => {

            let url =  Routes.sale.order.faturar.url;   
            return this.http.post(url,dataItem);

          },
          payment: (dataItem):any => {

            let url =  Routes.sale.order.payment.url;   
            return this.http.post(url,dataItem);

          },
          show: (id):any => {
            
            let url =  Routes.sale.order.order.url+"/"+id;   
            return this.http.get(url);
          
          },
          reopenOrder: (id):any => {

            let url =  Routes.sale.order.order.reopen;
            let params = {
              id: id
            }   

            return this.http.post(url,params);

          },
          getUserByCpf: (cpf):any => {

            let url =  Routes.sale.order.order.user_by_cpf;
            let params = {
              cpf: cpf 
            }   

            return this.http.get(url,{params});

          },
          voucher: ():any => {
            return {
              download: (id):any => {

                let url    = Routes.sale.order.order.voucher.download;
                let params = {
                  id: id
                }

                return this.http.post(url,params); 

              },
              email: (params):any => {

                let url  = Routes.sale.order.order.voucher.email;
            
                return this.http.post(url,params); 

              }
            }
          }
        }
      }
    }
  }
  /**
   * 
   * @returns 
   * 
   * 
   */
  products(){
    return{ 
      product: ():any => {
        return {
          hotels: (id):any => {

            let url =  Routes.products.product.hotels.url+"/"+id; 
            return this.http.get(url);

          },
          hotelOrigin: (id):any => {

            let url =  Routes.products.product.hotel_origin.url+"/"+id; 

            return this.http.get(url);

          },
          hotelDestination: (id):any => {

            let url =  Routes.products.product.hotel_destination.url+"/"+id; 
            return this.http.get(url);

          }, 
          list: (params):any => {

            let url =  Routes.products.product.list.url; 
            return this.http.get(url,{params});

          },
          types: (id):any => {

            let url =  Routes.products.product.types.url+"/"+id;
            return this.http.get(url);

          },
          disponibility: (id,typeServiceId,typeTransportId,month,year,dateIn=null,dateOut=null):any => {

            let url =  Routes.products.product.disponibility.url+"/"+id;

            let params = {
              type_service_id: typeServiceId,
              type_transport_id: typeTransportId,
              month: month,
              year: year,
              date_in: dateIn,
              date_out: dateOut 
            }

            return this.http.get(url,{params}); 

          }
        }
      }
    }
  }
  /**
   * 
   * Auth
   *  
   **/  
  auth(){
    return {
      login: (params):any => {

        let url =  Routes.auth.login
        return this.http.post(url,params);

      },
      logout: ():any => {

        let url =  Routes.auth.logout;
        return this.http.post(url,{});

      }
    }
  }

  /**
   * 
   * Test
   * 
   */
   request(){

    return {
      getData: (url,params={}):any => {
        return this.http.get(url,{params});
      },
      get: (url,params={}):any => {
        return this.http.get(url,{params});
      },
      post: (url,params={}):any => {

        let p:any      = params;
        let isFormData = typeof(p.isFormData) != "undefined" ? p.isFormData : false;

        if(isFormData === true || isFormData === "true"){

          const formData = new FormData();
          let data:any = Object.entries(p);

          for (let index = 0; index < data.length; index++) {
            
            formData.append(data[index][0],data[index][1]);   
            
          } 
          params = formData;

        }

        return this.http.post(url,params);

      },
      update: (url,params:any={}):any => {

        let u = url.split("?");
        let method = "PUT";

        if(u.length > 0){
          //url = u[0]+"/"+params.id+"?"+u[1];
          url = u[0]+"/"+params.id; 
        }else{
          url = u[0]+"/"+params.id;
        }

        let p:any      = params;
        let isFormData = typeof(p.isFormData) != "undefined" ? p.isFormData : false;
        method = typeof(p.method) != "undefined" ? p.method : "PUT";

        if(isFormData === true || isFormData === "true"){

          const formData = new FormData();
          let data:any = Object.entries(p);

          for (let index = 0; index < data.length; index++) {
            
            formData.append(data[index][0],data[index][1]);   
            
          } 
          params = formData;

        }

        return method == "PUT" ? this.http.put(url,params) : this.http.post(url,params);
        
      },
      delete: (url,params:any={}):any => {

        var id   = typeof(params.id) == "undefined" ? 0 : params.id;
        let link = this.formatUrlDelete(url,id); 

        return this.http.delete(link,{params});
        
      }
    }  
  }
  /**
   * 
   * Files
   * 
   */
   files(){

    return {
      upload: (path,file) =>{

        let url  = Routes.files.upload;
        const params = new FormData();

        params.append("file",file);
        params.append("url",path);

        return this.http.post(url,params)

      },
      read: (path) => {

        let url    = Routes.files.read;
        let params = {
          url: path
        }

        return this.http.get(url,{params});

      },
      combo: (path) => {
        
        let url    = Routes.files.combo;
        let params = {
          url: path
        }
        
        return this.http.get(url,{params});
        
      },
      delete: (file) => {
        let url    = Routes.files.delete;
        let params = {
          file: file
        }
        return this.http.delete(url,{params});
      },
      crop: (data) => {
        
        let url  = Routes.files.crop;
        const params = new FormData();

        params.append("file",data.file);
        params.append("path",data.path);
        params.append('x',data.x);
        params.append('y',data.y);
        params.append('cropW',data.cropW);
        params.append('cropH',data.cropH);
        params.append('width',data.width);
        params.append('height',data.height);
        params.append('tipo',data.tipo);
        params.append('rotate',data.rotate);
        params.append('formatos',data.formatos);

        return this.http.post(url,params)
      
      }
    }

  }
  /**
   * 
   * Formata a URL
   * 
   */
   formatUrlDelete(url,id){

    let data = url.split("?");

    if(data.length > 1){
      return data[0]+"/"+id+"?"+data[1];
    }else{
      return data[0]+"/"+id;
    }

  }
  /**
   * 
   * Formata as mensagens de erro 
   * 
   * @param err 
   * @param paxs 
   * 
   */
  formarErrorsValidation(err,paxs=null){
    
    let messages = ""
    
    if(typeof(err) == "string"){
      messages = err
    }else{
      if(err.length > 1){
           
        for(let key in err) {
          if(paxs){
            let i = parseInt(key)+1 
            messages += "<h6>Passageiro: "+i+"<h6>"
          }
          for(let k in err[key]){
            messages += err[key][k]+"<br>"
          }
                   
        }
      }else{
        for(let key in err[0]) {
          messages += err[0][key]+"<br>"
        }
     }  
    
    }
    
    return messages
    
  }
  /**
   * 
   * Formata o erro da API
   * 
   */
  formatError(response){

    let error = {
      status: null,
      statusText: null,
      message: null
    }

    if(response.statusText){
      error.statusText = response.statusText;
    }
    if(response.status){
      error.status = response.status;
    }
    if(error.status == 405){
      error.message = "Método não permitido.";
    }
    if(response.error && error.message == null){
      
      let body = response.error;

      if(error.status == 422){

        error.message = this.formarErrorsValidation([body.errors]);

      }else if(body.message){
        error.message = body.message;
      }

    }else{
      error.message = response.data;
    }

    return error;


  }

}
