import { AppService } from './../services/app.service';
import { Directive,ViewContainerRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[compileHtml]'
})
export class CompileHtmlDirective implements OnInit{
  
  
  @Input("compileHtml") template = "";
  @Input("context") context      = this;
  @Input("dataItem") dataItem    = null;
  @Input("grid")   grid          = null;
  @Input("data") data           = null;

  constructor(
    public container: ViewContainerRef,
    private app: AppService
  ) { 
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
    this.app.compileTemplate(this.template,this.container,this.context,true,this.dataItem,this.grid,this.data);
  }

}