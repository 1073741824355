import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit {

  @Output("reload") reload = new EventEmitter();
  @Input("detail") detail  = null;    

  constructor(){}  

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
