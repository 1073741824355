import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { DmGridFormFilterComponent } from '../dm-grid-form-filter/dm-grid-form-filter.component';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'dm-grid',
  templateUrl: './grid-one.component.html',
  styleUrls: ['./grid-one.component.scss']
})
export class DmGridComponent implements OnInit{ 
  
  public loader                                       = false;
  public loaderForm                                   = false;
  public reloadGrid                                   = false;
  @Input("config") config                             = null; 
  @Output("loadedData") loadedData                    = new EventEmitter();
  @ViewChild("content") content:ElementRef            = null;
  @ViewChild("matCheckItem") matCheckItem:MatCheckbox = null;  
  @Output("changeData") changeData = new EventEmitter();
  
  public checkAll = false;  
  public response = {
    data: "",
    status: null
  }

  public configGrid:any = {
    url: "",
    colunas: [],
    paginate:{
      pageLimit: 10,
      pageNumber: 0,
      order: {
        field: "id",
        dir: "desc"
      }
    },
    modal:{
      content: false,
      width: '800px',
      height: 'auto'
    },
    modalView:{
      content: false,
      width: '800px',
      height: 'auto'
    },
    actions: {
      delete: true,
      delete_toolbar: true,
      create: true,
      update: true,
      view: false
    },
    toolbar: {
      text_add: "NOVO",
      text_delete: "",
      templateHtml: [],
      actions: {
        delete: true
      }
    },
    navigation: null,
    params: {},
    dataItem: {},
    messages: {
      dataEmpty: "Não foi encontrado registros."
    },
    reload: () => {
      this.getData();
    }       
  }; 
  public data       = []; 
  public total      = 0;
  public modalForm:MatDialogRef<any>   = null;
  public modalView:MatDialogRef<any>   = null;
  public modalFilter:MatDialogRef<any> = null;
  public ids    = [];
  public filter = []; 
  public dataItem = null;
  public dataParams  = null;
  public paramsSearch = null;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    public app: AppService,
    public modalService: ModalService 
  ) { }

  /**
   * 
   * Salva os Dados
   * 
   * @param dataItem 
   * 
   */
  save(form){
    
    if(form.data.actionForm == 'update'){   
      if(typeof(form.dataItem.id) == "undefined"){
        form.data.self.app.info("o ID não foi informado",'info','500px'); 
        return false; 
      }  
    }
    let request = form.data.actionForm == "create" ? form.data.self.api.request().post(form.data.self.configGrid.url,form.dataItem) : form.data.self.api.request().update(form.data.self.configGrid.url,form.dataItem);

    form.loader = true;  
        
    request.subscribe(response => {

      form.loader = false;

      if(response.status == 1){

        form.data.self.modalService.open("Os dados foram salvos.");  
        form.data.self.reloadGrid = true;
        

        if(typeof(form.closeModal) != "undefined"){
          if(form.closeModal){
            form.dialog.close();
          }  
        }
        if(typeof(response.data.id) != "undefined"){
          form.dataItem.id = response.data.id;
        }
        if(typeof(response.data.apelido) != "undefined"){
          form.dataItem.apelido = response.data.apelido;
        }
        if(typeof(response.data.nickname) != "undefined"){
          form.dataItem.nickname = response.data.nickname;
        }
        if(typeof(form.saveSuccess) != "undefined"){
          form.saveSuccess();
        }
        form.data.actionForm      = "update";
        if(typeof(form.resetForm) != "undefined"){
          if(form.resetForm){
            form.initDataItem();
            form.data.actionForm = "create"; 
          }  
        }
        

      }else{
        
        let error   = form.data.self.api.formatError(response);
        form.data.self.modalService.open(error.message);

      }
      
      

    },(response) => {

      form.loader = false;
      
      let error   = form.data.self.api.formatError(response);
      form.data.self.modalService.open(error.message);
      
    });
    

  }
  /**
   * 
   * Form Modal
   * 
   */
  modal(){

    let self = this;

    return {
      view: (dataItem) => {
        
        self.modalView = this.dialog.open(this.configGrid.modalView.content, {
          width: this.configGrid.modalView.width,
          height: this.configGrid.modalView.height,
          data: {
            dataItem: dataItem,
            dataParams: this.dataParams,
            self: this
          }
        });
        self.modalView.afterClosed().subscribe((data) => {
          
        });

      },
      new: () => {

        self.modalForm = this.dialog.open(this.configGrid.modal.content, {
          width: this.configGrid.modal.width,
          height: this.configGrid.modal.height,
          data: {
            dataItem: null,
            actionForm: "create",
            save: this.save,
            params: this.configGrid.params,
            dataParams: this.dataParams,
            self: this
          }
        });
        self.modalForm.afterClosed().subscribe((data) => {
          if(self.reloadGrid){
            self.getData();
            self.reloadGrid = false;
          }
        });

      },
      edit: (dataItem) => {
 
        self.modalForm = this.dialog.open(this.configGrid.modal.content, {
          width: this.configGrid.modal.width,
          height: this.configGrid.modal.height,
          data: {
            dataItem: Object.assign({},dataItem),
            actionForm: "update",
            save: this.save,
            params: this.configGrid.params,
            dataParams: this.dataParams, 
            self: this
          }
        });
        self.modalForm.afterClosed().subscribe((data) => {
          if(self.reloadGrid){
            self.getData();
            self.reloadGrid = false;
          }
        });

      },
      filter: (coluna) => {

        self.modalFilter = this.dialog.open(DmGridFormFilterComponent, {
          width: "500px",
          height: "auto",
          data: {
            dataItem: Object.assign({},coluna),
            self: this,
            filters: this.filter
          }
        });

      }

    }

  }
  /**
   * 
   * Seta um item para o filtro
   * 
   * @param dataItem 
   * 
   */
  setFilter(dataItem,close=true,refresh=true){

    let index = null;

    if(this.filter.length == 0){
      this.filter.push(dataItem);
    }else{

      for(var i = 0; i < this.filter.length; i++) {
        if(this.filter[i].field == dataItem.field && this.filter[i].operador == dataItem.operador){
          index = i;
          break;
        }       
      }
      if(index != null){
        this.filter[index] = dataItem;
      }else{
        this.filter.push(dataItem);
      }

    }
    if(close){
      this.modalFilter.close();
    }
    if(refresh){
      this.getData(this.configGrid.paginate.pageLimit,0); 
    }  
    
  }
  /**
   * 
   * Remove um item do filter
   * 
   */
  removeFilter(field){

    if(this.filter.length > 0){

      let index = null;

      for(var i = 0; i < this.filter.length; i++) {
        if(this.filter[i].field == field){
          index = i;
          break;
        }       
      }
      if(index != null){
        this.filter.splice(index,1);
      }

    }
    this.modalFilter.close();
    this.getData();
    
  }
  /**
   * 
   * Verifica se o campo está no filtro
   * 
   */
  hasFilter(field){

    let status = false;

    for(var i = 0; i < this.filter.length; i++) {

      if(field == this.filter[i].field){
        status = true;
        break;
      }
      
    }

    return status;

  }
  /**
   * 
   * Busca os dados
   * 
   */
  getData(
    pageLimit=this.configGrid.paginate.pageLimit,
    pageNumber=this.configGrid.paginate.pageNumber,
    navigation=this.configGrid.navigation,
    loader=true
  ){

    try{

      this.loader = loader;

      this.api.request().get(this.configGrid.url,{
        skip: (pageLimit*pageNumber),
        pageSize: pageLimit, 
        order: JSON.stringify(this.configGrid.paginate.order),
        filter: JSON.stringify(this.filter),
        navigation: navigation,
        params: JSON.stringify(this.config.params),
        paramsSearch: JSON.stringify(this.paramsSearch),
      }).subscribe(resp => {

        let response:any = resp;
        this.loader      = false;

        if(response.status == 1){

          this.data       = response.data;
          this.total      = response.total;
          this.dataParams = typeof(response.params) ? response.params : null;

          this.changeData.emit({
            data: this.data,
            total: this.total
          });

          this.configGrid.paginate.pageLimit  = pageLimit;
          this.configGrid.paginate.pageNumber = pageNumber;

          this.loadedData.emit(response);

          this.response.status = 1;

        }else{

          if(response.status == 405){

            this.app.info(response.data);   
            return false;
          }

          this.response = response;

        }

      },(response) => {
        
        this.loader = false;
        let error   = this.api.formatError(response);
        
        this.response = {
          data: response.message,
          status: 500
        }

      });

    }catch(e){

    }

  }
  /**
   * 
   * Ordena pela coluna clicada
   * 
   */
  toOrder(coluna,index){
    
    this.setColunasOrderAtivo(false);
    coluna.order.ativo = true
    coluna.order.dir   = coluna.order.dir == "desc" ? "asc" : "desc";

    this.configGrid.paginate.order = {
      field: coluna.order.field,
      dir: coluna.order.dir
    }
    this.getData();
  }
  /**
   * 
   * Seta o valor para a ordernação
   * 
   */
  setColunasOrderAtivo(value){

    for(var index = 0; index < this.configGrid.colunas.length; index++) {
      
      this.configGrid.colunas[index].order.ativo = value;
      
    }

  }
  
  /**
   * 
   * Init Order Colunas
   * 
   */
  initValuesColunas(){


    if(typeof(this.configGrid.colunas) == "object"){
      
      for (var index = 0; index < this.configGrid.colunas.length; index++) {
        
        this.configGrid.colunas[index].order = {
          field: this.configGrid.colunas[index].field,
          dir: "desc",
          ativo: false
        }
        if(typeof(this.configGrid.colunas[index].context) == "undefined"){
          this.configGrid.colunas[index].context = this;
        }
        if(typeof(this.configGrid.colunas[index].type) == "undefined"){
          this.configGrid.colunas[index].type = "text";
        }
        if(typeof(this.configGrid.colunas[index].filter) == "undefined"){
          this.configGrid.colunas[index].filter       = true;
        }
        if(typeof(this.configGrid.colunas[index].filter_value) == "undefined"){
          this.configGrid.colunas[index].filter_value = null;
        }
        
      }

    }

  }
  /**
   * 
   *  Troca de Página
   * 
   */
  changePage(page){

    this.getData(this.configGrid.paginate.pageLimit,page);

  }
  /**
   * 
   * Troca o Limite da listagem
   * 
   */
  changePageLimit(pageLimit){
    this.getData(pageLimit,this.configGrid.paginate.pageNumber);
  }
  /**
   * 
   * Seta os valores para a configuração
   * 
   * 
   */
  setValuesConfig(config){

    if(config != null){
  
      if(config.url){
        this.configGrid.url = config.url;
      }
      if(config.colunas){
        this.configGrid.colunas = config.colunas;
      }
      if(config.paginate){
        if(config.paginate.pageLimit){
          this.configGrid.paginate.pageLimit = config.paginate.pageLimit;
        }
        if(config.paginate.pageNumber){
          this.configGrid.paginate.pageNumber = config.paginate.pageNumber; 
        }
        if(config.paginate.order){
          if(config.paginate.order.field){
            this.configGrid.paginate.order.field = config.paginate.order.field;
          }
          if(config.paginate.order.dir){
            this.configGrid.paginate.order.dir = config.paginate.order.dir;
          }
        }
      }
      if(config.modal){
        if(config.modal.content){
          this.configGrid.modal.content = config.modal.content;
        }
        if(config.modal.width){
          this.configGrid.modal.width = config.modal.width;
        }
        if(config.modal.height){
          this.configGrid.modal.height = config.modal.height;
        }
      }
      if(config.modalView){
        if(config.modalView.content){
          this.configGrid.modalView.content = config.modalView.content;
        }
        if(config.modalView.width){
          this.configGrid.modalView.width = config.modalView.width;
        }
        if(config.modalView.height){
          this.configGrid.modalView.height = config.modalView.height;
        }
      }
      if(config.actions){
        if(typeof(config.actions.create) != "undefined"){  
          this.configGrid.actions.create = config.actions.create;
        }
        if(typeof(config.actions.update) != "undefined"){
          this.configGrid.actions.update = config.actions.update;
        }
        if(typeof(config.actions.delete) != "undefined"){
          this.configGrid.actions.delete = config.actions.delete;
        }
        if(typeof(config.actions.view) != "undefined"){
          this.configGrid.actions.view = config.actions.view;
        }
        if(typeof(config.actions.delete_toolbar) != "undefined"){  
          this.configGrid.actions.delete_toolbar = config.actions.delete_toolbar;
        }
      }
      if(config.toolbar){
        if(typeof(config.toolbar.text_add) != "undefined"){
          this.configGrid.toolbar.text_add = config.toolbar.text_add;
        }
        if(typeof(config.toolbar.text_delete) != "undefined"){
          this.configGrid.toolbar.text_delete = config.toolbar.text_delete;
        }
        if(typeof(config.toolbar.templateHtml) != "undefined"){
          this.configGrid.toolbar.templateHtml = config.toolbar.templateHtml;
        }
        if(typeof(config.toolbar.actions) != "undefined"){
          if(typeof(config.toolbar.actions.delete) != "undefined"){
            this.configGrid.toolbar.actions.delete = config.toolbar.actions.delete;
          }
        }
      }
      if(config.params){
        this.configGrid.params = config.params;
      }
      if(config.dataItem){
        this.configGrid.dataItem = config.dataItem;
      }
      if(config.paramsSearch){
        this.paramsSearch = config.paramsSearch;
      }

    }  
    this.config.setDataItem = (dataItem) => {
      this.dataItem = dataItem;
    }
    this.config.getDataItem = () => {
      return this.dataItem;
    }
    this.config.getDataParams = () => {
      return this.dataParams;
    }
    this.config.setParamsSearch = (dataItem) => {
      this.paramsSearch = dataItem;
    }
    this.config.getFilter = () => {
      return this.filter;
    }
    this.config.setFilter = (dataItem) => {
      this.setFilter(dataItem,false,false);
    }
    this.config.clearFilter = () => {
      this.filter = [];
    }
    this.config.setParams = (params) => {
      this.config.params = params;
    }
    this.config.refresh = (loader=true) => {
      this.getData(
        this.configGrid.paginate.pageLimit,
        this.configGrid.paginate.pageNumber,
        this.configGrid.navigation,
        loader
      );
    }
    this.config.navigation = (navigation=null) => {
      this.getData(this.configGrid.paginate.pageLimit,this.configGrid.paginate.pageNumber,navigation);
    }
    

  }
  /**
   * 
   * DataItem coluna
   * 
   */
  dataItemColuna(field,value,item,indexColuna){
    
    let dataItem = {};
    dataItem[field]         = value;
    dataItem["id"]          = item.id;
    dataItem["indexColuna"] = indexColuna;

    return dataItem;

  }
  /**
   * 
   * 
   */
  onRefresh(){

  }
   /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setValuesConfig(this.config);
    this.getData(this.configGrid.paginate.pageLimit,this.configGrid.paginate.pageNumber);
    this.initValuesColunas();
  }

}

