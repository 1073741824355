import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmComboDataComponent } from 'src/app/components/combo/dm-combo-data/dm-combo-data.component';
import { DmComboComponent } from 'src/app/components/combo/dm-combo/dm-combo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComboManagerDestinoComponent } from 'src/app/components/combo/combo-manager-destino/combo-manager-destino.component';
import { ComboManagerOrigemComponent } from 'src/app/components/combo/combo-manager-origem/combo-manager-origem.component';


@NgModule({
  declarations: [
    DmComboDataComponent,
    DmComboComponent,
    ComboManagerDestinoComponent,
    ComboManagerOrigemComponent
  ],
  exports: [  
    DmComboDataComponent, 
    DmComboComponent,
    ComboManagerDestinoComponent,
    ComboManagerOrigemComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule  
  ]
})
export class ComboModule { }
