import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

declare var $:any;  

@Component({
  selector: 'combo-passengers',
  templateUrl: './combo-passengers.component.html',
  styleUrls: ['./combo-passengers.component.scss']
})
export class ComboPassengersComponent implements OnInit {

  @Input("label") label = null;
  @Input("value") value = null;
  @Input("text") text   = null;
  @Input("data") data   = [];
  @ViewChild("select") select:ElementRef; 
  @Output("changeValue") changeValue = new EventEmitter();
  public comboFilter:Observable<any>;
  public loader         = false;
  public response       = {
    data: [],
    status: null,
    message: ""
  };
  @Input("paramsAtributos") paramsAtributos = {
    text: "text",
    value: "value"
  }
  
  constructor(
    private api: ApiService,
    private app: AppService
  ){ }

   /**
   * 
   * Get dataItem
   * 
   */
  _getDataItem(){

    let data = null; 

    for(let index = 0; index < this.data.length; index++) {

      if(this.value == this.data[index]["_id"]){  

        data = this.data[index]; 
        break;

      }
      
    }

    return data;

  }
  /***
   * 
   * Verifica a mudança
   * 
   */
  _change(){

     
    let item = this._getDataItem();
    
    if(item != null){
      this.changeValue.emit(item);
    }

  }
  /**
  * 
  * 
  * 
  */
  _getData(){
    
    if(this.response.status != 1){
      $(this.select.nativeElement).select2("close");
    }

  }
  initSelect(){

    let self = this;
    
    $(this.select.nativeElement).val(this.value).select2({
      allowClear: false
    });
    $(this.select.nativeElement).on("select2:select", function (e) {

      let value = $(this).val();        

      self.value = value;
      self._change();  
  
    });

  }
  /**
   * 
   * Inicializando as Funções
   * 
   */
  ngOnInit(){}
  /**
   * 
   * Init after view
   * 
   */
  ngAfterViewInit(): void {
    this.initSelect();
  }

}
