import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  public configuration = null;

  public logo = "/assets/logo/managetour.ng";

  constructor(
    public storage: StorageService,
    public app: AppService
  ) { }

  /**
   * 
   * Set Data
   * 
   */
  setData(){

    this.configuration = this.storage.getConfiguration();
    this.setLogo();

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.app.updateConfiguration.subscribe((response) => {

      this.configuration = response;
      this.setLogo();

    })

  }
  /**
   * 
   * Set logo
   * 
   */
  setLogo(){

    if(this.configuration.logo != "" && this.configuration.logo != null){
      this.logo = "/assets/logo/app/"+this.configuration.logo;
    }

  }
  /**
   * 
   * Set configuration
   * 
   */
  ngOnInit(): void {
    this.setData();
    this.onData();
  }

}
