import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cart-order-link',
  templateUrl: './cart-order-link.component.html',
  styleUrls: ['./cart-order-link.component.scss']
})
export class CartOrderLinkComponent implements OnInit {

  @Input("id") id:any     = null;
  @Input("hash") hash:any = null;

  constructor(){}  

  /**
   * 
   * Open
   * 
   */
  _open(){

    window.open("/order/payment/"+this.hash,"_blank");  
    return false;

  }
  /**
   * 
   * Init
   * 
   * 
   ***/
  ngOnInit():void{}   

}
