import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

declare var $:any;

@Component({
  selector: 'cart-date',
  templateUrl: './cart-date.component.html',
  styleUrls: ['./cart-date.component.scss']
})
export class CartDateComponent implements OnInit {

  @ViewChild('datepicker') datepicker;
  @Input("value") value                               = null;
  @Input("id") id                                     = null; // product id
  @Input("typeServiceId") typeServiceId               = null;
  @Input("macroRegionId") macroRegionId               = 1; 
  @Input("typeTransportId") typeTransportId           = null;
  @Input("dateIn") dateIn                             = null;
  @Input("dateOut") dateOut                           = null; 
  @Output("changeValue") changeValue                  = new EventEmitter();  
  public loader                                       = false;
  @Input("disponibilidadeArray") disponibilidadeArray = null;
  public month = null;
  public year = null;
  public messageLoader = "";
  @Input("frequenciaApartirDe") frequenciaApartirDe   = null;
  public message       = null;
  public minDate:any       = new Date();

  constructor(
    private api: ApiService
  ) { }

  /**
  * 
  * Cria o calendário
  * 
  */
 create(){
   
  let self = this;

  $(this.datepicker.nativeElement).datepicker({
     dateFormat: "dd/mm/yy",
     minDate: new Date(),
     defaultDate: null,
     changeMonth: true,
     changeYear: false, 
     monthNamesShort: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
     dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','Sab'],
     monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
     beforeShowDay: this.habilitarDates,
     onSelect: (value)=>{
      
      let data   = $(this.datepicker.nativeElement).val();
      this.value = data;
      
      this.changeValue.emit({
        date: data,
        disponibilityArray: this.disponibilidadeArray
      });
      
      setTimeout(() => {
        //self.preencheValoresCalendario(self); 
      });
           

     },
     onChangeMonthYear: (year,month) =>{
 
      this.month = month;
      this.year  = year;

      //this.getData();

     } 

   }).addClass("cart-date-datepicker");

   $(this.datepicker.nativeElement).datepicker("setDate",null);  

   if(this.dateIn != null){
    $(this.datepicker.nativeElement).datepicker("option","minDate",this.dateIn)
    $(this.datepicker.nativeElement).datepicker("option","changeMonth",false)
   }
   if(this.dateOut != null){  
    $(this.datepicker.nativeElement).datepicker("option","maxDate",this.dateOut)
   }

   setTimeout(() => {
    //self.preencheValoresCalendario(self); 
   });
   
 }
  /**
  * 
  * Habilita somente as datas disponíveis
  * 
  */
  habilitarDates = (d: Date) => {
    
    let dataDia       = null;
    
    if(dataDia = this.hasDate(d)){
      
      let idTipoServico = dataDia.idTipoServico;
      let valor         = idTipoServico == 2 ? dataDia.tarifaServico : dataDia.tarifaADT;

      return [true,"date-td date-td-valor",valor];

    }else{
      return [false,"date-td",null];
    }

  }
  /**
  * 
  * 
  * 
  */
 hasDate = (d: Date): boolean =>{

    let result:boolean = false; 
    let dataS    = null
    let dataOut  = null
    let datas    = this.disponibilidadeArray;
    let response:any = false;

    if(typeof(datas) != "string"){
      datas = typeof(datas.length) == "undefined" ? [datas] : datas; 
    }else{
      datas = [];
    }
    datas.forEach(element => {
        
      dataS   = element.dia.split("/"); 
      dataOut = new Date(parseInt(dataS[2]),(parseInt(dataS[1])-1),parseInt(dataS[0]));
        
      if(dataOut.getDate() == d.getDate() && dataOut.getMonth() == d.getMonth() && dataOut.getFullYear() == d.getFullYear()
        ){
        response = element;   
        result = true
      }

    });
    return response;

  }
  /**
   * 
   * Retorna a disponibilidade
   * 
   */
  getData(){

    try{

      this.loader = true;
      this.messageLoader = "Carregando disponibilidade.";

      this.api.products().product().disponibility(
        this.id,
        this.typeServiceId,
        this.typeTransportId,
        this.month,
        this.year,
        this.dateIn,
        this.dateOut 
      ).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          
          this.disponibilidadeArray = response.data;
          this.create(); 
          $(this.datepicker.nativeElement).datepicker("refresh");
          setTimeout(() => {

            if(this.disponibilidadeArray.length > 0){
              //$(this.datepicker.nativeElement).datepicker("setDate",this.disponibilidadeArray[0].dia);
            }
            if(this.frequenciaApartirDe != null){
              //$(this.datepicker.nativeElement).datepicker("minDate",this.frequenciaApartirDe);
            }

          },200);
          setTimeout(() => {
            //this.preencheValoresCalendario();
          },500);
          
        }

      },(response) => {

        this.loader = false;

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Preenche valores
   * 
   */
  preencheValoresCalendario(self=this){

    $(self.datepicker.nativeElement).find(".date-td-valor").map(function(){
      let valor = this.title;
      $(this).append("<span class='valor'>"+valor+"</span>"); 
    });

  }
  /**
   * 
   * 
   * 
   */
  formatarData(data){

    if(data != null){

      let d   = data.split("/");
      let dia = d[0];
      let mes = d[1];
      let ano = d[2];

      this.month    = mes;
      this.year     = ano; 
      this.minDate = new Date(dia+"-"+mes+"-"+ano);

    }


  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
    this.formatarData(this.frequenciaApartirDe);

  }
  /**
   * 
   * Iniciliza as Funções após carregar a VIEW
   * 
   */
  ngAfterViewInit(){

    if(this.disponibilidadeArray != null && this.value != null){
      
      this.create();
      $(this.datepicker.nativeElement).datepicker("setDate",this.value);
    
    }else{
  
      setTimeout(() => {
        this.getData();
      },200);  

    }
     
  }

}
