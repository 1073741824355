import { Injectable } from '@angular/core';
import { ValidatorService } from './validator.service';

@Injectable()
export class ServicoService {

  constructor(
    private validator: ValidatorService
  ) { }

  /**
   * 
   * Valid Step 1
   * 
   */
  validTrajeto(dataItem){

    let response = {
      data: "",
      status: true
    }

    this.validator.clear();    
    if(dataItem.require_origin){
      
      this.validator.isEmpty(dataItem.id_local_pick_up,"id_local_pick_up","- Informe o Local de Saída.");
      
      if(dataItem.address_require_local_origin){
        this.validator.isEmpty(dataItem.address_local_origin,"address_local_origin","- Informe o Endereço (Local de Saída).");
      }
      
    }
    if(dataItem.require_destination){
      
      this.validator.isEmpty(dataItem.id_local_drop_off,"id_local_drop_off","- Informe o Local de Destino.");
      
      if(dataItem.address_require_local_destination){
        this.validator.isEmpty(dataItem.address_local_destination,"address_local_destination","- Informe o Endereço (Local de Destino).");
      }
    
    }
    if(dataItem.type_local_destination == "AEROPORTO" && dataItem.type_local_destination == "AEROPORTO"){

      this.validator.isEmpty(dataItem.hotel_id,"hotel_id","- Informe o Hotel."); 
      if(dataItem.address_require){

      }

    }

    if(!this.validator.passes()){
      response.data    = this.validator.getMessagesHtml();
      response.status  = false;
    }
    
    return response;

  }
  /**
   * 
   * Agrupa por tipo de serviço
   * 
   */
  agrupaPorTipoServico(data){

    let response         = [];
    let countTipo        = 0;
    let y:any            = false;
    let contemRegular    = false;
    let contemPrivativo = false;

    for(let index = 0;index < data.length;index++){
      
      y = this.inArray(data[index]["idTipoServico"],"idTipoServico",response);

      if(y == null){

        if(parseInt(data[index]["idTipoServico"]) == 1){
          contemRegular = true;
        }
        if(parseInt(data[index]["idTipoServico"]) == 2){
          contemPrivativo = true; 
        }
        response.push({
          tipoServico: data[index]["tipoServico"],
          idTipoServico: data[index]["idTipoServico"],
          lista:[data[index]]
        });
        countTipo++;

      }else{

        response[y].lista.push(data[index]); 

      }
      
    }

    return {
      response: response,
      countTipo: countTipo,
      contemRegular: contemRegular,
      contemPrivativo: contemPrivativo
    }

  }
  /**
   * 
   * Verifica se tem no array
   * 
   */
  inArray(value,campo,data){

    let index:any = null;

    for(let i = 0;i < data.length;i++) {
      
      if(data[i][campo] == value){
        index = i;
      }
      
    }

    return index;

  }
  /**
   * 
   * Valid Step 1
   * 
   */
  validStep1(dataItem){

    let response = {
      data: "",
      status: true
    }

    if(dataItem.macro_region_id == null){
      
      response = {
        data: "- Selecione a REGIÃO.",
        status: false
      }

    }
    return response;

  }
  /**
   * 
   * Valid Step 2
   * 
   */
  validStep2(dataItem){

    let response = {
      data: "",
      status: true
    }

    if(dataItem.type_service_id == null){
      response = {
        data: "- Escolha o TIPO DE SERVIÇO.",
        status: false
      }
    }else{
      if(dataItem.type_service_id == 2){
        if(dataItem.type_transport_id == null){
          response = {
            data: "- Escolha o TRANSPORTE.",
            status: false
          }
        }
      }
    }
    return response;

  }
  /**
   * 
   * Valid Step 3
   * 
   */
  validStep3(dataItem){

    let response = {
      data: "",
      status: true
    }

    if(dataItem.hotel_id == null){
      
      response = {
        data: "- Selecione uma HOSPEDAGEM.",
        status: false
      }

    }else if(dataItem.address_require){

      if(dataItem.address === null || dataItem.address === ""){  
        response = {
          data: "- Informe o endereço.",
          status: false
        }
      }  

    }
    return response;
  
  }
  /**
   * 
   * Valid Step 4
   * 
   */
  validStep4(dataItem){

    let response:any = {
      data: "",
      status: true
    }
    if(dataItem.date === null || dataItem.date === "null"){
      
      response = {
        data: "- Selecione uma DATA.",
        status: false
      }

    }
    if(response.status == true){
      
      if(dataItem.tariff_adt == 0 && dataItem.type_service_id == 1){
        
        response.data   += response.status == true ? "- Esse serviço está sem valor" : "<br>- Esse serviço está sem valor";
        response.status = false;  

      }else if(dataItem.tariff_service == 0 && dataItem.type_service_id == 2){

        response.data  += response.status == true ? "- Esse serviço está sem valor" : "<br>- Esse serviço está sem valor";
        response.status = false;

      }

    }  
    return response;

  }
  /**
   * 
   * Valid Step 4
   * 
   */
   validQtds(dataItem){

    let response:any = {
      data: "",
      status: true
    }
    
    let total = dataItem.qtd_adt+dataItem.qtd_snr+dataItem.qtd_chd+dataItem.qtd_inf;
    if(total == 0){
      
      response = { 
        data: "- Informe a quantidade de passageiros.",
        status: false
      }

    }
 
    return response;

  }
  /**
   * 
   * Valid Step 1
   * 
   */
  validHorario(dataItem){

    let response = {
      data: "",
      status: true
    }

    this.validator.clear();
    this.validator.isEmpty(dataItem.horario_in,"horario_in","- Selecione o Horário de Partida.");
    this.validator.isEmpty(dataItem.horario_out,"horario_out","- Selecione o Horário de Retorno.");
    this.validator.isEmpty(dataItem.data_partida,"data_partida","- Informe a Data de Retorno."); 

    let dt1 = this.formatDate(dataItem.data_partida);
    let dt2 = this.formatDate(dataItem.data_utilizacao);  

    this.validator.isDateMaior(dt1,dt2,"data_partida","- A data partida não pode ser menor que "+dataItem.data_utilizacao+" ."); 


    if(!this.validator.passes()){
      response.data    = this.validator.getMessagesHtml();
      response.status  = false;
    }
    
    return response;

  }
  /**
   * 
   * Valid Dados Voo
   * 
   */
  validFlight(dataItem){

    let response = {
      data: "",
      status: true
    }

    let product_in_out = dataItem.service_in_out;  

    if(product_in_out === false || product_in_out === "false"){
      
      this.validator.clear();
      this.validator.isEmpty(dataItem.airport,"aeroporto","- Informe o AEROPORTO.");
      this.validator.isEmpty(dataItem.airline_code,"cia_aerea","- Informe o CIA AÉREA.");
      this.validator.isEmpty(dataItem.flight_number,"voo","- Informe o VÔO.");

      if(dataItem.flight_time !== "" && dataItem.flight_time !== null && dataItem.flight_time !== "null"){
        this.validator.isTime(dataItem.flight_time,"fligh_time","- A Hora do VOO é inválida."); 
      }

    }else{

      this.validator.clear();
      this.validator.isEmpty(dataItem.airport,"aeroporto_chegada","- Informe o AEROPORTO (Chegada).");
      this.validator.isEmpty(dataItem.airline_code,"cia_aerea","- Informe o CIA AÉREA (Chegada).");
      this.validator.isEmpty(dataItem.flight_number,"voo","- Informe o VÔO (Chegada).");
      
      if(dataItem.flight_time !== "" && dataItem.flight_time !== null && dataItem.flight_time !== "null"){
        this.validator.isTime(dataItem.flight_time,"fligh_time","- A Hora do VOO é inválida."); 
      }

      this.validator.isEmpty(dataItem.airport_out,"aeroporto_partida","- Informe o AEROPORTO (Partida).");
      this.validator.isEmpty(dataItem.airline_code_out,"cia_aerea_partida","- Informe o CIA AÉREA (Partida).");
      this.validator.isEmpty(dataItem.flight_number_out,"voo_partida","- Informe o VÔO (Partida)."); 
      this.validator.isEmpty(dataItem.date_out,"data_partida","- Informe a Data de Partida.");  

      if(dataItem.flight_time_out !== "" && dataItem.flight_time_out !== null && dataItem.flight_time_out !== "null"){
        this.validator.isTime(dataItem.flight_time_out,"fligh_time_out","- A Hora do VOO é inválida (Partida)."); 
      }

      if(dataItem.date_out !== null && dataItem.date_out !== ""){
        let dt1 = this.formatDate(dataItem.date_out);
        let dt2 = this.formatDate(dataItem.date); 

        this.validator.isDateMaior(dt1,dt2,"data_partida","- A data partida não pode ser menor que "+dataItem.date+" ."); 
      }

    }  
    if(!this.validator.passes()){
      response.data    = this.validator.getMessagesHtml();
      response.status  = false;
    }
    
    return response;

  }
  /***
   * 
   * Format Date
   * 
   * 
   */
  formatDate(data){

    let d = data.split("/");

    return d[1]+"/"+d[0]+"/"+d[2]; 

  }
  /**
   * 
   * Valid Step 4
   * 
   */
  validDadosPassageiros(data){

    let response = {
      data: "",
      status: true
    }
    
    for(let index = 0; index < data.length; index++) {
            
      if(!data[index].isSelect){
        this.validator.clear();
        this.validator.isEmpty(data[index].nome,"nome","- Informe o Nome.");
        this.validator.isEmpty(data[index].sobrenome,"sobrenome","- Informe o Sobrenome.");
        this.validator.isEmpty(data[index].documento,"documento","- Informe o RG ou Certidão de Nascimento.");

        if(data[index].tipo == "adt" || data[index].tipo == "snr"){
          this.validator.isEmpty(data[index].telefone,"telefone","- Informe o Celular.");
        }
        //if(this.validator.isEmpty(data[index].cpf,"_cpf","- ")){
          ///this.validator.validCpf(data[index].cpf,"cpf","- Informe um CPF válido.")
        //}

        if(!this.validator.passes()){
          response.data   += "<div class='modal-passageiro'><h6>Passageiro "+(index+1)+"</h6>"; 
          response.data   += this.validator.getMessagesHtml();
          response.data   += "</div>";
          response.status  = false;
        }
      }  
      

    }

    return response;

  }
  /**
   * 
   * Retona as tarifas com base na data
   * 
   */
  getTarifasByDate(data,disponibilidade){

    let response = {
      valor_adt: 0,
      valor_chd: 0,
      valor_snr: 0,
      valor_servico: 0,
      tipo_contratacao: 1 
    }

    for(let index = 0;index < disponibilidade.length;index++) {
      
      if(disponibilidade[index].dia == data){
        response.valor_adt        = disponibilidade[index].tarifaADT;
        response.valor_chd        = disponibilidade[index].tarifaCHD;
        response.valor_snr        = disponibilidade[index].tarifaSNR;
        response.valor_servico    = disponibilidade[index].tarifaServico;
        response.tipo_contratacao = disponibilidade[index].tipoContratacaoTarifa;
        break;
      }
      
    }

    return response;

  }
  /**
   * 
   * Formatar tarifa
   * 
   */
  formatarTarifa(tarifa){

    tarifa = tarifa.replace(new RegExp(/[.]/g),"");
    tarifa = tarifa.replace(new RegExp(/[,]/g),".");

    return parseFloat(tarifa); 

  }
  /**
   * 
   * Retorna as tarfifas de uma data
   * 
   */
  getTarifasByDate2(d,idZonaHotel=null,idCidadeMunicipio=null,disponibilidade,tipo=1){

    let item               = null;
    let valor_chd          = 0;
    let valor_adt          = 0;
    let valor_snr          = 0;
    let valor_servico      = 0;
    let tipo_contratacao   = 1;
    let codigo_moeda       = "";
    let cambio             = 0;
    let codigo_moeda_id    = null;
    let _idCidadeMunicipio = null;
    let _idZonaHotel       = null;
    disponibilidade        = typeof(disponibilidade[0]) == "undefined" ? [disponibilidade] : disponibilidade;

    if(idCidadeMunicipio != null){
      _idCidadeMunicipio = idCidadeMunicipio.length == 0 ? null : idCidadeMunicipio;
    }
    if(tipo == 2){
      idCidadeMunicipio = null;
    }
    if(idZonaHotel != null){
      _idZonaHotel = idZonaHotel;
    }

    disponibilidade.forEach(element => {

      if(idZonaHotel != null && idCidadeMunicipio != null){
        
        if(element.dia == d && parseInt(element.idZonaRegiaoHotel) == parseInt(idZonaHotel) && parseInt(element.idCidadeMunicipio) == parseInt(idCidadeMunicipio)){
          item = element;
        }

      }else if(idZonaHotel != null){  
        
        if(element.dia == d && parseInt(element.idZonaRegiaoHotel) == parseInt(idZonaHotel)){
          item = element
        }  

      }else{
        
        let _item      = element;
        let _tarifaADT = null;
        let tarifaADT  = null;
        
        if((element.dia == d) && (parseInt(element.idCidadeMunicipio) == parseInt(idCidadeMunicipio)) && (item == null)){ 
                
          item = element;   
        
        }else{

          if(!isNaN(parseInt(element.idCidadeMunicipio))){
            
            if((element.dia == d) && (parseInt(element.idCidadeMunicipio) == parseInt(idCidadeMunicipio))){
              _tarifaADT = this.formatarTarifa(element.tarifaADT); 
              tarifaADT = this.formatarTarifa(item.tarifaADT);       
              
              if(_tarifaADT < tarifaADT){ 
                item = element; 
              }

            }else{
              if(element.dia == d){
                _tarifaADT = this.formatarTarifa(element.tarifaADT); 
                tarifaADT  = item == null ? null : this.formatarTarifa(item.tarifaADT);       
                
                if(tarifaADT == null){
                  item = element; 
                }else{
                  if(_tarifaADT < tarifaADT){ 
                    item = element; 
                  }
                }  
  
              }
            }
          }else{
            if(element.dia == d){
              _tarifaADT = this.formatarTarifa(element.tarifaADT); 
              tarifaADT  = item == null ? null : this.formatarTarifa(item.tarifaADT);       
              
              if(tarifaADT == null){
                item = element; 
              }else{
                if(_tarifaADT < tarifaADT){ 
                  item = element; 
                }
              }  

            }
          }  

        } 

      }
      
      
    });
    if(item != null){

      valor_adt        = this.formatarTarifa(item.tarifaADT);
      valor_chd        = this.formatarTarifa(item.tarifaCHD);
      valor_snr        = this.formatarTarifa(item.tarifaSNR);
      valor_servico    = this.formatarTarifa(item.tarifaServico);
      tipo_contratacao = item.tipoContratacaoTarifa;
      codigo_moeda     = item.codigoMoeda;
      codigo_moeda_id  = item.idMoeda;
      cambio           = this.formatarTarifa(item.cambio);

      return {
        valor_adt: valor_adt,
        valor_chd: valor_chd,
        valor_snr: valor_snr,
        valor_servico: valor_servico,
        codigo_moeda: codigo_moeda,
        cambio: cambio,
        codigo_moeda_id: codigo_moeda_id,
        tipo_contratacao: tipo_contratacao
      }

    }else{
      
      let _data:any = {
        valor_adt: null,
        valor_chd: null,
        valor_snr: null,
        valor_servico: null,
        codigo_moeda: null,
        codigo_moeda_id: null,
        cambio: null,
        tipo_contratacao: null
      }
      if(tipo == 1){

        _data = this.getTarifasByDate2(d,idZonaHotel,_idCidadeMunicipio,disponibilidade,2);

        valor_adt     = _data.valor_adt;
        valor_chd     = _data.valor_chd;
        valor_snr     = _data.valor_snr;
        valor_servico = _data.valor_servico;
        codigo_moeda  = _data.codigo_moeda;
        tipo_contratacao = _data.tipo_contratacao;
        cambio           = _data.cambio;
        codigo_moeda_id  = _data.codigo_moeda_id;

      }else if(tipo == 2){
      
        _data = this.getTarifasByDate2(d,null,_idCidadeMunicipio,disponibilidade,3);

        valor_adt     = _data.valor_adt;
        valor_chd     = _data.valor_chd;
        valor_snr     = _data.valor_snr;
        valor_servico = _data.valor_servico;
        codigo_moeda  = _data.codigo_moeda;
        codigo_moeda_id  = _data.codigo_moeda_id;
        cambio            = _data.cambio;
        tipo_contratacao  = _data.tipo_contratacao;

      }

    }
    
    return {
      valor_adt: valor_adt,
      valor_chd: valor_chd,
      valor_snr: valor_snr,
      valor_servico: valor_servico,
      codigo_moeda: codigo_moeda,
      codigo_moeda_id: codigo_moeda_id,
      cambio: cambio,  
      tipo_contratacao: tipo_contratacao
    }
    

  }


}
