import { Component, OnInit, Input, Renderer2, ElementRef, forwardRef, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';

declare var $:any;

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DmComboDataComponent),
  multi: true,
};

@Component({
  selector: 'dm-combo-data',
  templateUrl: './dm-combo-data.component.html',
  styleUrls: ['./dm-combo-data.component.scss'],
  providers: [
    VALUE_ACCESSOR
  ]
})
export class DmComboDataComponent extends DefaultValueAccessor implements OnInit,AfterViewInit {
  
  @Input("data") data     = [];
  @Input("label") label   = false; 
  @Input("ngModel") value = null;
  @Input("text") text     = null;
  @Input("name") name     = "value";
  @Input("type") type     = "request";
  @ViewChild("select") select:ElementRef;
  @Output("changeValue") changeValue = new EventEmitter();
  @Input("clear") clear = new EventEmitter();  
  @Input("search") search:any = true;
  
  public comboFilter: Observable<any>;
  public isOpenSelect = false;

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
    private api: ApiService,   
    private app: AppService
  ) { 
    
    super(renderer2,el,false);
      
  }
  
  /**
   * 
   * Atualiza o valor na VIEW
   * 
   */
  onChangeValue(){
    
    super.writeValue(this.value);
    this.onChange(this.value);
    
  }
   /**
   * 
   * Init select
   * 
   */
    initSelect(){

      let self = this;
  
      $(self.select.nativeElement).val(self.value).select2({
        allowClear: false,
        minimumResultsForSearch: this.search === false || this.search === "false" ? -1 : 2
      });
      $(self.select.nativeElement).on("select2:select", function (e) {
        
        let value = $(this).val();        
  
        self.value = value;
        self._change();  
      
      });
  
  
  }
  /***
   * 
   * Verifica a mudança
   * 
   */
   _change(){

      
    let item = this._getDataItem();
    
    if(item != null){
      this.changeValue.emit(item);
    }else{
      this.changeValue.emit({
        value: null
      });
    }
    this.onChangeValue();

  }
  /**
   * 
   * Get dataItem
   * 
   */
   _getDataItem(){

    let data = null;

    for(let index = 0; index < this.data.length; index++) {

      if(this.value == this.data[index]["value"]){

        data = this.data[index];
        break;

      }
      
    }

    return data;

  }
  onData(){

    this.clear.subscribe(data => {

      $(this.select.nativeElement).val('').trigger('change')  

    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.onData();
  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.initSelect();
  }

}
