import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ModalService } from 'src/app/services/modal.service';

declare var QRCode:any;

@Component({
  selector: 'payment-pix',
  templateUrl: './payment-pix.component.html',
  styleUrls: ['./payment-pix.component.scss']
})
export class PaymentPixComponent implements OnInit {

  @Input("data") data:any = {};
  public loader = false;
  @Output("confirmed") confirmed:any = new EventEmitter();

  constructor(
    private app: AppService,
    private api: ApiService,
    private modal: ModalService,
    private recaptchaV3Service: ReCaptchaV3Service
  ){}

  /**
   * 
   * Get payemnt
   * 
   */
  getPayment(){

    try{

      this.loader = true;  

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          let dataItem = {
            token: token,
            id: this.data.order.id
          }

          this.api.orderLink().order().paymentConsult(dataItem).subscribe((response:any) => {

            this.loader = false;

            if(response.status == 1){

              this.confirmed.emit(response.data.consulta);

            }else if(response.status == 2){

              this.modal.open("Ainda não identificamos o pagamento do seu pix. Tente novamente ou entre em contato com nossa equipe.");

            }else{

                let error   = this.api.formatError(response);
                this.app.info(error.message);

            }

          },(response:any) => {

            this.loader = false;
            let error   = this.api.formatError(response);
            this.modal.open(error.message);

          });

        },(response:any) => { 

          this.loader   = false;
          this.app.info("Token de validação inválido. Recarregue a página para continuar.");
  
      });

    }catch(e){

      this.loader = false;
      this.modal.open(e.message);

    }

  }
  /**
   * 
   * Create QrCode
   * 
   */
  createQrCode(){

    var qrcode = new QRCode(document.getElementById("qr-code-pix"), {
      text: this.data.payment.codigo_qrcode,
      width: 220,
      height: 220,
      colorDark : "#000000",
      colorLight : "#ffffff",
      correctLevel : QRCode.CorrectLevel.H
    });

  }
  /**
   * 
   * Copy QrCode
   * 
   */
  copyQrCode(){

    var copyText:any = document.getElementById("inputQr");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 9999); /* For mobile devices */

     /* Copy the text inside the text field */
    navigator.clipboard.writeText(this.data.payment.codigo_qrcode);  

    this.modal.open(  
      "<strong>O código foi copiado.</strong><br>Agora cole o código em seu aplicativo de internet banking para realizar o pagamento."
    ); 

  }

  /***
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}
  /**
   * 
   * After View
   * 
   */
  ngAfterViewInit(): void {
    this.createQrCode();
  }

}
