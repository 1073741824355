import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from 'src/app/components/partial/loader/loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageErrorComponent } from 'src/app/components/partial/page-error/page-error.component';

@NgModule({
  declarations: [
    LoaderComponent,
    PageErrorComponent 
  ],
  exports: [
    LoaderComponent,
    PageErrorComponent   
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class PartialModule {}  
