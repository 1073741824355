import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteAdminGuard } from './guard/route-admin-guard';

const routes: Routes = [{
  path: "",
  loadChildren: () => import('src/app/modules/pages/home-page/home-page.module').then(m => m.HomePageModule),
},{
  path: "login",
  loadChildren: () => import('src/app/modules/pages/auth-page/auth-page.module').then(m => m.AuthPageModule),
},{
  path: "user/profile",    
  loadChildren: () => import('src/app/modules/pages/user/user-profile-page/user-profile-page.module').then(m => m.UserProfilePageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "register/users",    
  loadChildren: () => import('src/app/modules/pages/register/user-page/user-page.module').then(m => m.UserPageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "sale/orders",   
  loadChildren: () => import('src/app/modules/pages/sales/orders-page/orders-page.module').then(m => m.OrdersPageModule),
},{
  path: "sale/products",
  loadChildren: () => import('src/app/modules/pages/sales/products/products.module').then(m => m.ProductsModule),
},{
  path: "sale/cart", 
  loadChildren: () => import('src/app/modules/pages/sales/cart-page/cart-page.module').then(m => m.CartPageModule),
},{
  path: "sale/cart/payment-approved",  
  loadChildren: () => import('src/app/modules/pages/sales/payment-approved-page/payment-approved-page.module').then(m => m.PaymentApprovedPageModule),
},{
  path: "sale/payment-link",  
  loadChildren: () => import('src/app/modules/pages/sales/payment-link/payment-link.module').then(m => m.PaymentLinkModule),
},{
  path: "dashboard",  
  loadChildren: () => import('src/app/modules/pages/dashboard-page/dashboard-page.module').then(m => m.DashboardPageModule),
},{
  path: "payment-link/order/paid",  
  loadChildren: () => import('src/app/modules/pages/payment-link/payment-link-paid-page/payment-link-paid-page.module').then(m => m.PaymentLinkPaidPageModule),
},{
  path: "payment-link/order/:id",  
  loadChildren: () => import('src/app/modules/pages/payment-link/payment-link-order-page/payment-link-order-page.module').then(m => m.PaymentLinkOrderPageModule),
},{
  path: "order/payment/:id",  
  loadChildren: () => import('src/app/modules/pages/sales/order-payment-link-page/order-payment-link-page.module').then(m => m.OrderPaymentLinkPageModule),
},{
  path: "order/payment/:id/confirmation",   
  loadChildren: () => import('src/app/modules/pages/sales/order-payment-link-confirmation-page/order-payment-link-confirmation-page.module').then(m => m.OrderPaymentLinkConfirmationPageModule),
},{   
  path: '**',  
  pathMatch: 'full', 
  redirectTo: ''   
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,{relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
