import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'cart-passenger-qtd',
  templateUrl: './cart-passenger-qtd.component.html',
  styleUrls: ['./cart-passenger-qtd.component.scss']
})
export class CartPassengerQtdComponent implements OnInit {

  @Input("dataItem") dataItem:any = {};
  @Input("product") product:any   = {}; 
  @Input("qtd_adt") qtd_adt:any   = 0;
  @Input("qtd_chd") qtd_chd:any   = 0;
  @Input("qtd_snr") qtd_snr:any   = 0;
  @Input("qtd_inf") qtd_inf:any   = 0;
  public qtd_total            = 1;
  @Output("changeValue") changeValue = new EventEmitter();
  @Input("configuracaoLoja")  configuracaoLoja:any = {};
  public lotacao_maxima         = null;
  public validar_lotacao_maxima = false;
  public message       = {
    data: "",
    show: false,
    class: ""
  }
  
  constructor(
    public modal: ModalService,
    public storage: StorageService
  ){ }

  /**
   * 
   * Adiciona
   * 
   */
  mais(campo){

    if(this.validar_lotacao_maxima){
      if(this.qtd_total >= this.lotacao_maxima){ 
        this.modal.open("A lotação máxima é de "+this.lotacao_maxima+" lugares.");
        return false;
      }
    }

    switch(campo){
      case 'adt':
        this.qtd_adt++;
        this.calcularQtdTotal();
        this._changeValue();
      break;
      case 'chd':
        this.qtd_chd++;
        this.calcularQtdTotal();
        this._changeValue();
      break;
      case 'snr':
        this.qtd_snr++;
        this.calcularQtdTotal();
        this._changeValue();
      break;
      case 'inf':
        this.qtd_inf++;
        this.calcularQtdTotal();
        this._changeValue();
      break;
    }

  }
  /**
   * 
   * Diminui
   * 
   */
  menos(campo){
    
    switch(campo){
      case 'adt':
        if(this.qtd_adt >= 1){ 
          this.qtd_adt--;
          this.calcularQtdTotal();
          this._changeValue();
        }  
      break;
      case 'chd':
        if(this.qtd_chd >= 1){
          this.qtd_chd--;
          this.calcularQtdTotal();
          this._changeValue();
        }  
      break;
      case 'snr':
        if(this.qtd_snr >= 1){
          this.qtd_snr--;
          this.calcularQtdTotal();
          this._changeValue();
        }  
      break;
      case 'inf':
        if(this.qtd_inf >= 1){
          this.qtd_inf--;
          this.calcularQtdTotal();
          this._changeValue();
        }  
      break;
    }

  }
  /**
   * 
   * Calcula qtds total
   * 
   */
  calcularQtdTotal(){

    let qtd_adt = isNaN(parseInt(this.qtd_adt)) ? 0 : parseInt(this.qtd_adt);
    let qtd_chd = isNaN(parseInt(this.qtd_chd)) ? 0 : parseInt(this.qtd_chd);
    let qtd_inf = isNaN(parseInt(this.qtd_inf)) ? 0 : parseInt(this.qtd_inf);
    let qtd_snr = isNaN(parseInt(this.qtd_snr)) ? 0 : parseInt(this.qtd_snr);

    this.qtd_total = qtd_adt+qtd_chd+qtd_inf+qtd_snr; 
    

  }
  /**
   * 
   * Change Value
   * 
   */
  _changeValue(){

    this.calcularQtdTotal();
    
    if(this.validar_lotacao_maxima){
 
      if(this.qtd_total > this.lotacao_maxima){ 
        return false;
      }
    }
 
    this.changeValue.emit({
      qtd_adt: this.qtd_adt,
      qtd_chd: this.qtd_chd,
      qtd_snr: this.qtd_snr,
      qtd_inf: this.qtd_inf 
    });

  }
  /**
   * 
   * Seta a lotação máxima
   * 
   */
  setLotacaoMaxima(){
    
    this.lotacao_maxima = this.dataItem.lotacao_maxima === null || this.dataItem.lotacao_maxima == `[]` ? null : parseInt(this.dataItem.lotacao_maxima);
    
    if(this.lotacao_maxima != null && this.dataItem.tipo_servico_id == 2){ 
      this.validar_lotacao_maxima = true; 
    }

  }
  /**
   * 
   * Show
   * 
   * @param message 
   * @param classCustom 
   * 
   */
  showModalMessage(message,classCustom="alert alert-info"){  

    this.message.data  = message;
    this.message.class = classCustom;
    this.message.show  = true;

  }
  /**
   * 
   * Set labels
   * 
   */
  setLabels(){

    this.product.passenger_label_adt = this.product.passenger_label_adt == "" || this.product.passenger_label_adt == null ? this.configuracaoLoja.passenger_label_adt : this.product.passenger_label_adt;
    this.product.passenger_label_chd = this.product.passenger_label_chd == "" || this.product.passenger_label_chd == null ? this.configuracaoLoja.passenger_label_chd : this.product.passenger_label_chd;
    this.product.passenger_label_snr = this.product.passenger_label_snr == "" || this.product.passenger_label_snr == null ? this.configuracaoLoja.passenger_label_snr : this.product.passenger_label_snr;
    this.product.passenger_label_chd_free = this.product.passenger_label_chd_free == "" || this.product.passenger_label_chd_free == null ? this.configuracaoLoja.passenger_label_chd_free : this.product.passenger_label_chd_free;

  }
  /***
   * 
   * Change ADT
   * 
   */
  _changeAdt(){

    /*if(this.qtd_adt !== ""){   

      if(this.qtd_adt < this.min_adt){
        this.qtd_adt = this.min_adt;
      }

    }*/
    this._changeValue();  

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.configuracaoLoja = this.storage.getConfiguration();
    this.setLabels();
    this.setLotacaoMaxima();
  }

}
