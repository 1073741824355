import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

declare var $:any;

@Component({
  selector: 'combo-manager-destino',
  templateUrl: './combo-manager-destino.component.html',
  styleUrls: ['./combo-manager-destino.component.scss']
})
export class ComboManagerDestinoComponent implements OnInit {

  @Input("label") label = "Escolha o local de origem:";
  @Input("value") value = null;
  @Input("text") text   = null;
  @Input("info") info   = null;
  @ViewChild("select") select:ElementRef; 
  @Output("changeValue") changeValue = new EventEmitter();
  @Input("id") id = null;
  public comboCtrl        = new FormControl();
  public comboFilter:Observable<any>;
  public loader         = false;
  public response:any       = {
    data: [],
    status: null,
    message: ""
  };
  public paramsAtributos = {
    text: "hotelPousada",
    value: "idHotelPousada"
  }
  public data = [];
  public isOpenSelect = false;
  
  constructor(
    private api:ApiService,
    private app: AppService
  ){ }
  
  /**
   * 
   * Retorna os dados para o ComboBox
   * 
   * 
   */
  getData(open=true){

    try{

      this.loader = true; 
      this.api.products().product().hotelDestination(this.id).subscribe(response => {

        this.loader = false;
        
        if(response.status == 1){

          this.data            = response.data;

          if(this.data.length == 0){
            this.app.info("Não encontramos hóteis para listar para você.");
          }

          this.response.status = 1;
          if(this.isOpenSelect){
            setTimeout(() => { 
              //this.initSelect(); 
              $(this.select.nativeElement).select2("close"); 
              if(open){
                $(this.select.nativeElement).select2("open"); 
              } 
            },100); 
          }  
          
        }else{

          this.response = {
            data: [],
            status: 500,
            message: response.data
          }

        }  

      },(response) => {

        this.loader = false;

        let error     = this.api.formatError(response);
        this.response = {
          data: [],
          status: 500, 
          message: error.message
        };

      });

    }catch(e){

      this.loader = false;

    }

  }
   /**
   * 
   * Get dataItem
   * 
   */
  _getDataItem(){

    let data = null;

    for(let index = 0; index < this.data.length; index++) {

      if(this.value == this.data[index]["idHotelPousada"]){

        data = this.data[index];
        break;

      }
      
    }

    return data;

  }
  /***
   * 
   * Verifica a mudança
   * 
   */
  _change(){

    try{
      
    let item = this._getDataItem();
    
    if(item != null){
      this.changeValue.emit(item);
    }
    }catch(e){
        alert(e.message)
    }

  }
  /**
  * 
  * 
  * 
  */
  _getData(){
    
    if(this.response.status != 1){
      this.isOpenSelect = true;
      this.getData();
    }

  }
  initSelect(){

    let self = this;
    
    $(this.select.nativeElement).val(this.value).select2({
      allowClear: false
    });
    $(this.select.nativeElement).on("select2:select", function (e) {

      //this returns all the selected item
      let value = $(this).val();        

      self.value = value;
      self._change();  
  
    });

  }
  /**
   * 
   * Inicializando as Funções
   * 
   */
  ngOnInit(){

  }
  ngAfterViewInit(): void {
    this.initSelect();
  }

}
