import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ModalService } from 'src/app/services/modal.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'payment-link-form',
  templateUrl: './payment-link-form.component.html',
  styleUrls: ['./payment-link-form.component.scss']
})
export class PaymentLinkFormComponent implements OnInit {

  public title    = "Link de Pagamento:";
  public dataItem = null;
  public response = {
    data: null,
    status: 1
  }
  public loader = false;

  constructor(
    public dialog:MatDialogRef<PaymentLinkFormComponent>=null,
    @Inject(MAT_DIALOG_DATA) public data=null,
    private api: ApiService,
    private modal: ModalService,
    private router: Router,
    private storage: StorageService
  ){

    if(data != null){

      if(data.dataItem != null){
        this.dataItem   = data.dataItem;
        this.title = `Link de Pagamento ${this.dataItem.id}:`;
      }else{
        this.initDataItem();
      }

    }else{
      this.initDataItem();
    } 

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
   initDataItem(){

    this.dataItem = {
      name: "",
      active: true
    }
    
    this.data.actionForm  = "create";

  }
   /**
   * 
   * Save DATA
   * 
   */
  save(close=false,resetForm){ 
    
    //this.closeModal = close;
    //this.resetForm  = resetForm;
    this.data.save(this);

  }
  /***
   * 
   * Init
   * 
   * 
   */
  ngOnInit():void{}

}
