import { Component, ElementRef } from '@angular/core';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { ModalService } from './services/modal.service';
import { StorageService } from './services/storage.service';

declare var $:any;
declare var urlMetrix:any;
declare var dataConfiguracao:any; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    AppService,ApiService,ModalService
  ]
})
export class AppComponent {
  
  title = 'portal';
  public paymentLink = false;
  public orderLink   = false;
  public metrixRead  = false;
  public configuracao = null;

  constructor(
    private elementRef:ElementRef,
    private storage: StorageService
  ){
    
    let paymentLink = this.elementRef.nativeElement.getAttribute('paymentLink') ? this.elementRef.nativeElement.getAttribute('paymentLink') : false;
    let orderLink   = this.elementRef.nativeElement.getAttribute('orderLink') ? this.elementRef.nativeElement.getAttribute('orderLink') : false;
    
    if(paymentLink === true || paymentLink === "true" || paymentLink === 1 || paymentLink === "1"){
      this.paymentLink = true;
    }else{
      this.paymentLink = false;
    }

    if(orderLink === true || orderLink === "true" || orderLink === 1 || orderLink === "1"){
      this.orderLink = true; 
    }else{
      this.orderLink = false;
    }

    this.configuracao = JSON.parse(dataConfiguracao); 
    
    this.storage.setConfiguration(this.configuracao);

  }
  
  

}
